/*RESET*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::selection {
  background-color: var(--tertiary-color);
  color: #fff;
}

body {
  font-size: 10px;
}

/*VARIABLES*/
:root {
  --main-color: #193459;
  --secondary-color: #2b5273;
  --tertiary-color: #186373;
  --accent-color: #02a5a6;
  --white-color: #fff;
  --black-color: #000;
}

.App {
  overflow-x: hidden;
}

.load-animation {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("/public/imgs/animation.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 50%;
  z-index: 1000;
  pointer-events: none;
}

.animation-mobile {
  animation: load-init 0.5s linear 1s normal forwards;
}

@keyframes load-init {
  0% {
    filter: opacity(1);
  }
  100% {
    filter: opacity(0);
  }
}

.animation-desktop {
  display: none;
}

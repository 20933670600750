.form-section {
  width: 100vw;
  margin-bottom: 3rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-text {
  font-size: 1.75rem;
  text-align: center;
  font-weight: 700;
  line-height: 5rem;
  color: var(--main-color);
  margin-bottom: 2rem;
}

input {
  width: 300px;
  height: 50px;
  padding: 0.5em;
  font-size: 1.2rem;
  display: flex;
  margin: 0 0 1em;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  outline: none;
}

textarea {
  display: flex;
  height: 140px;
  border-radius: 5px;
  font-size: 1.2rem;
  padding: 0.5em;
  border: 1px solid #e6e6e6;
  resize: none;
  width: 350px;
}

.btn-submit {
  width: 121px;
  height: 52px;
  border-radius: 5px;
  border-style: none;
  text-align: center;
  font-size: 1.2rem;
  background-color: var(--accent-color);
  color: #ffff;
  margin-top: 1rem;
}

.Toastify__toast-body {
  font-size: 1rem;
}

@media screen and (min-width: 1000px) {
  .quienes-somos {
    flex-direction: row;
    height: 60vh;
  }

  .quienes-somos-img {
    height: 60vh;
  }

  .text-container {
    width: 45vw;
  }

  .title-quienes-somos {
    text-align: center;
    font-size: 2.25rem;
    line-height: 2rem;
  }

  .text-quienes-somos {
    text-align: center;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1420px) {
  .text-container {
    padding: 0;
  }

  .quienes-somos{
    height: 65vh;
  }
}

@media screen and (min-width: 1420px) {
  .text-container {
    padding: 1rem 2rem 0;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1250px) {
  .quienes-somos-img {
    width: 50vw;
    height: 85vh;
    object-fit: cover;
  }

  .quienes-somos{
    height: 85vh;
  }
}

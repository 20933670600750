@media screen and (min-width: 1000px) {
  .home {
    justify-content: unset;
    align-items: unset;
    background-size: cover;
  }

  .home-container {
    position: relative;
    width: 100%;
  }

  .home-box {
    margin: 10rem 10% 0;
  }

  .home-title {
    font-size: 35px;
    text-align: left;
    margin: 0 0 1rem 0;
  }

  .home-subtitle {
    text-align: left;
    font-weight: 300;
    font-size: 25px;
    margin: unset;
  }
}

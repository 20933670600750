@media screen and (min-width: 1000px) {
  .services-box {
    margin: 10px;
    overflow-x: hidden;
  }

  .services-box-img {
    filter: drop-shadow(0 110px 0 #000);
    transition: all 0.2s;
  }

  .services-box:hover .services-box-img {
    height: 80px;
    transform: translateY(-110px);
  }

  .services-box:hover .services-box-title,
  .services-box:hover .services-box-text {
    transform: translateY(-15px);
  }

  .arrow-left {
    left: 20vw;
  }

  .arrow-right {
    right: 20vw;
  }
}

@media screen and (min-width: 1066px) {
  .services-container {
    width: 80vw;
    margin: 0 auto;
  }

  .services-box:last-child {
    width: 940px;
    height: 250px;
  }
}

@media screen and (min-width: 1600px) {
  .services-container {
    width: 60vw;
  }
}

@media screen and (min-width: 2130px) {
  .services-container {
    width: 50vw;
  }
}

@media screen and (min-width: 688px) and (max-width: 1200px) {
  .services-box:last-child {
    height: 300px;
    width: 610px;
  }
}

@media screen and (min-width: 1000px){

    .nosotros{
        background-color: #ffff;
    }

    .nosotros-container{
        background: rgba(236, 231, 231, 0.33);
    }
    .nosotros-title {
        width: 100%;
        font-size: 1.75rem;
        font-weight: 600;
        line-height: 1.125rem;
        letter-spacing: 0.2px;
        color: var(--main-color);
        margin: 2rem;
        text-align: center;
        transition: font-size 0.2s;
        position: relative;
      }
    
    .nosotros-text {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.75rem;
        letter-spacing: 0.2px;
        color: #606265;
        margin: 1rem;
        text-align: center;
        padding: 5px 20px;
      }  
}

.services {
  width: 100vw;
  background: linear-gradient(180deg, rgba(2, 165, 166, 0) 0%, rgba(24, 99, 115, 0.4) 40%);
}

.services-arrow-title {
  position: relative;
}

.services-title {
  width: 100%;
  text-align: center;
  font-size: 1.75rem;
  margin: 2rem 0 1rem;
}

.services-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 5rem;
  margin: 0 5vw;
}

.services-box {
  width: 300px;
  height: 400px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.services-box-img {
  height: 65px;
  margin: 1rem 0;
  object-fit: cover;
}

.services-box:last-child {
  justify-content: center;
}

.services-box-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 5px;
  margin: 1rem 0;
  font-size: 1.5rem;
  transition: all 0.5s;
  background-color: #fff;
  padding-bottom: 1.5rem;
  position: relative;
  z-index: 100;
}

.services-box-text {
  font-size: 1.25rem;
  margin-top: -1.5rem;
  padding: 5px 20px;
  transition: all 0.5s;
  background-color: #fff;
  position: relative;
  z-index: 100;
}

/* ARROW */

.arrow-left {
  position: absolute;
  top: 1.5rem;
  left: 10vw;
  background-color: #000;
  width: 20vw;
  height: 1.5px;
}

.arrow-left::before {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: #000;
  transform: rotate(45deg);
}

.arrow-right {
  position: absolute;
  top: 1.5rem;
  right: 10vw;
  background-color: #000;
  width: 20vw;
  height: 1.5px;
}

.arrow-right::after {
  content: "";
  position: absolute;
  top: -2px;
  right: 0;
  width: 5px;
  height: 5px;
  background-color: #000;
  transform: rotate(45deg);
}

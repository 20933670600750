.footer {
  width: 100vw;
  padding-bottom: 5rem;
  background-color: var(--accent-color);
}

.networks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  margin-top: 2rem;
}

.network-img {
  color: var(--white-color);
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.network-facebook {
  width: 17px;
  height: 17px;
  margin-left: 2px;
}

.mail-location-box {
  margin-top: 2rem;
}

.fb-instagram-box {
  margin-top: 2rem;
  text-align: center;
}

.network-txt {
  color: var(--white-color);
  font-size: 1rem;
  font-weight: 400px;
  vertical-align: middle;
}

.home {
  height: 90vh;
  width: 100vw;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to left, #18637366 40%, #193459 100%), url("/public/imgs/Consultora.png");
  background-repeat: no-repeat;
  background-size: auto 100vh;
  color: var(--white-color);
  background-attachment: fixed;
}

.home-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-box {
  margin-top: 20vh;
}

.home-title {
  font-size: 30px;
  text-align: center;
  margin: 0 5% 1rem;
}

.home-subtitle {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  margin: 0 8%;
}

.nosotros {
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: var(--accent-color);
}

.nosotros-container {
  width: 300px;
  height: 400px;
  margin: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.nosotros-container:hover {
  transform: translateY(-10px);
  box-shadow: 0px 14px 4px rgba(0, 0, 0, 0.25);
}

.nosotros-container:hover .nosotros-title {
  font-size: 2rem;
}

.nosotros-container:hover .nosotros-title::after {
  width: 50%;
}

.nosotros-title {
  width: 100%;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.2px;
  color: var(--main-color);
  margin: 1.25rem;
  text-align: center;
  transition: font-size 0.2s;
  position: relative;
}

.nosotros-title::after {
  content: "";
  width: 0;
  height: 2px;
  background-color: #000;
  position: absolute;
  bottom: -10px;
  left: 25%;
  transition: width 0.2s;
}

.nosotros-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.50rem;
  letter-spacing: 0.2px;
  color: #606265;
  margin: 0.25rem;
  text-align: center;
  padding: 5px 20px;
}

.values {
  width: 100vw;
  margin: 2rem 0;
}

.swiper-wrapper {
  align-items: flex-start;
}

.swiper-slide {
  text-align: left;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
}

.value-title {
  color: var(--main-color);
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  line-height: 1;
}

.value-txt {
  font-size: 1rem;
  border-bottom: 1.5px solid #000a;
}

.title-box {
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000;
}

/* ARROW */

.arrow-left {
  position: absolute;
  top: 1.5rem;
  left: 10vw;
  background-color: #000;
  width: 20vw;
  height: 1.5px;
}

.arrow-left::before {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: #000;
  transform: rotate(45deg);
}
.arrow-right {
  position: absolute;
  top: 1.5rem;
  right: 10vw;
  background-color: #000;
  width: 20vw;
  height: 1.5px;
}

.arrow-right::after {
  content: "";
  position: absolute;
  top: -2px;
  right: 0;
  width: 5px;
  height: 5px;
  background-color: #000;
  transform: rotate(45deg);
}

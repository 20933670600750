.nav-container {
  height: 10vh;
  width: 100vw;
  position: fixed;
  z-index: 500;
  top: 0;
  background-color: var(--accent-color);
  box-shadow: 0 5px 10px #193459aa;
}

.nav-menu {
  height: 100%;
}

.nav-menu-box {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-menu-title {
  font-size: 1.25rem;
  color: var(--white-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.nav-menu-title-img {
  height: 30px;
  margin-bottom: 10px;
}

.nav-menu-title-word {
  margin: 0 2px;
  font-weight: 700;
}

.nav-menu-list {
  height: 90vh;
  width: 50vw;
  padding-bottom: 40vh;
  position: absolute;
  top: 10vh;
  left: 0;
  background-color: var(--secondary-color);
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  color: var(--white-color);
  font-size: 1rem;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.menu-open::before {
  content: "";
  position: absolute;
  top: 0;
  left: 49.92vw;
  width: 50vw;
  height: 90vh;
  z-index: -1;
  animation: menu-bg 0.2s linear 0.3s normal forwards;
}

@keyframes menu-bg {
  from {
    background-color: transparent;
  }
  to {
    background-color: #0005;
  }
}

.menu-open {
  transform: translateX(0);
}

.nav-menu-list-box {
  position: relative;
  display: flex;
  align-items: center;
}

.nav-menu-list-icon {
  position: absolute;
  left: 1rem;
  width: 1.6rem;
  height: 1.6rem;
}

.nav-menu-link {
  margin-left: 15vw;
  width: 25vw;
}

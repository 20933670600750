/*MEDIA QUERYS*/

@media screen and (min-width: 1000px) {
  .nav-container {
    height: 15vh;
  }

  .nav-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-menu-title-img {
    position: absolute;
    right: -3vw;
    width: 3vw;
    height: auto;
    margin: 0;
    padding: 0 0 18px 5px;
  }

  .nav-menu-title {
    font-size: 3rem;
    margin-left: 3vw;
  }

  .nav-menu-title-word {
    font-size: 4vw;
    width: 2.5vw;
    overflow: hidden;
    transition: width 0.5s;
    margin: 0;
  }

  .nav-menu-title:hover .b1 {
    animation: title 0.5s linear normal forwards;
  }

  .nav-menu-title:hover .b2 {
    animation: title 0.5s linear 0.25s normal forwards;
  }

  .nav-menu-title:hover .nav-menu-title-img {
    animation: logo 0.5s linear normal forwards;
  }

  @keyframes title {
    0% {
      width: 2.6vw;
    }
    50% {
      width: 18vw;
    }
    100% {
      width: 18vw;
      overflow: visible;
      filter: drop-shadow(3px 0 2px #193459);
    }
  }

  @keyframes logo {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-2.5vw);
      filter: drop-shadow(3px 0 2px #193459);
    }
  }

  .nav-menu-list {
    transform: translate(0);
    padding-bottom: 0;
    position: relative;
    flex-direction: row;
    height: 100%;
    top: 0;
    background-color: unset;
    margin: 0 5vw;
    font-size: 1.25rem;
    align-items: center;
  }

  .menu-open::before {
    display: none;
  }

  .nav-menu-list-box {
    cursor: pointer;
  }

  .nav-menu-link {
    width: unset;
    margin-left: 0;
    padding: 2rem;
  }

  .nav-menu-list-icon {
    left: -0.5rem;
    width: 2rem;
    height: 2rem;
  }

  .navigator {
    width: 8vw;
    height: 5px;
    background-color: transparent;
    position: absolute;
    top: 10vh;
    left: 2.6vw;
    transition: 0.2s;
    transform: translate(-100%);
    border-radius: 5px;
  }

  .toService {
    transform: translateX(-4%);
    background-color: #fff;
  }
  .toValues {
    transform: translateX(148%);
    background-color: #fff;
  }
  .toAbout {
    transform: translateX(302%);
    background-color: #fff;
  }
  .toContact {
    transform: translateX(462%);
    background-color: #fff;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .toService {
    transform: translateX(-30%);
  }
  .toValues {
    transform: translateX(138%);
    background-color: #fff;
  }
  .toAbout {
    transform: translateX(308%);
    background-color: #fff;
  }
  .toContact {
    transform: translateX(484%);
    background-color: #fff;
  }
}

.nav-menu-icon {
  position: absolute;
  width: 4vh;
  height: 4vh;
  top: 3vh;
  left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: unset;
  border: none;
  transition: all 0.3s;
}

.bar-1,
.bar-2,
.bar-3 {
  background-color: var(--white-color);
  width: 100%;
  height: 3px;
  pointer-events: none;
  transition: all 0.3s;
}

.open {
  transform: translateX(10px);
}

.open .bar-1 {
  transform: rotate(45deg) translateY(2vh);
  border-radius: 50%;
}

.open .bar-2 {
  filter: opacity(0);
}

.open .bar-3 {
  transform: rotate(-45deg) translateY(-2vh);
  border-radius: 50%;
}

/*MEDIA QUERYS*/

@media screen and (min-width: 1000px) {
  .nav-menu-icon {
    display: none;
  }

  .nav-menu-list-icon{
    display: none;
  }
}
